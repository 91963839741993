const Admin = () => import("@/components/admin/Admin.vue");
const AdminDepartments = () =>
  import("@/components/admin/AdminDepartments.vue");
const AdminCareGroups = () =>
  import("@/components/admin/groups/AdminCareGroups.vue");
const AdminEdsGroups = () =>
  import("@/components/admin/groups/AdminEDSGroups.vue");
const AdminUsers = () => import("@/components/admin/AdminUsers.vue");
const AdminParameters = () =>
  import("@/components/admin/parameters/AdminParameters.vue");
const AdminTasks = () => import("@/components/admin/tasks/AdminTasks.vue");
const AdminIndexation = () =>
  import("@/components/admin/indexation/AdminIndexation.vue");
const AdminCustomApp = () =>
  import("@/components/admin/customization/AdminCustomApp.vue");
const AdminCustomAppEdition = () =>
  import("@/components/admin/customization/AdminCustomAppEdition.vue");
const AdminOpposition = () =>
  import("@/components/admin/AdminCareOpposition.vue");
const AdminWorkspacesParameters = () =>
  import("@/components/admin/workspaces/AdminWorkspaces.vue");
const AdminHistoric = () => import("@/components/admin/AdminHistoric.vue");
const AdminFeatureFlag = () => import("@/components/admin/AdminFeatureFlag.vue");
import {MODE} from "@/config";

let meta;
let additionalRoutes = [];

switch (process.env.VUE_APP_MODE) {
  case MODE.CARE:
    meta = {disableWorkspaces: true};
    additionalRoutes.push(
      {
        path: "groups",
        name: "AdminCareGroups",
        component: AdminCareGroups,
        props: true,
        meta: meta,
      },
      {
        path: "oppositions",
        name: "AdminOpposition",
        component: AdminOpposition,
        props: true,
        meta: meta,
      },
      {
        path: "custom_app",
        name: "AdminCustomApp",
        component: AdminCustomApp,
        meta: meta,
      }
    );
    break;
  case MODE.EDS:
    meta = {disableOpposition: true};
    additionalRoutes.push(
      {
        path: "groups",
        name: "AdminEdsGroups",
        component: AdminEdsGroups,
        props: true,
        meta: meta,
      },
      {
        path: "workspaces",
        name: "AdminWorkspacesParameters",
        component: AdminWorkspacesParameters,
        props: true,
        meta: meta,
      },
      {
        path: "custom_app",
        name: "AdminCustomApp",
        component: AdminCustomApp,
        meta: meta,
      },
      {
        path: "custom_app/:page",
        name: "AdminCustomAppEdition",
        component: AdminCustomAppEdition,
        props: true,
        meta: { disableSubNavBar: true, ...meta },
      }
    );
    break;
  case MODE.RESEARCH:
    meta = {disableCustom: true, disableOpposition: true, disableWorkspaces: true};
    break;
}

const routes = {
  name: "Admin",
  path: "/admin",
  redirect: { name: "AdminUsers" },
  component: Admin,
  meta: meta,
  children: [
    ...additionalRoutes,
    {
      path: "users",
      name: "AdminUsers",
      component: AdminUsers,
      props: true,
      meta: meta,
    },
    {
      path: "services",
      name: "AdminDepartments",
      component: AdminDepartments,
      props: true,
      meta: meta,
    },
    {
      path: "parameters",
      name: "AdminParameters",
      component: AdminParameters,
      props: true,
      meta: meta,
    },
    {
      name: "AdminIndexation",
      path: "indexation",
      component: AdminIndexation,
      meta: meta,
    },
    {
      name: "AdminTasks",
      path: "tasks",
      component: AdminTasks,
      meta: meta,
    },
    {
      name: "AdminHistoric",
      path: "historic",
      component: AdminHistoric,
      meta: meta,
    },
    {
      name: "AdminFeatureFlag",
      path: "featureflag",
      component: AdminFeatureFlag,
      meta: meta,
    }
  ],
};

export const adminRoutes = routes;
