import {Status} from "@/views/commons/search/utils/enums";
import {Errors} from "@/enums/response_errors";

const getDefaultState = () => ({
  mixinResults: {
    body: {},
    Status,
    Errors,
    status: Status.NONE,
    valid: true,
    valid_status: Status.NONE,
    count_result: {authorized_patients: undefined},
    error: null,
    verbose_body: null,
    fetched_patients: {},
    results_structure: [],
    search_request_id: null,
    highlight_snippets: {},
    offset_next_page: null,
  },
  timeConstraints: null,
  timeConstraintsHighlight: null,
});

const state = getDefaultState();

const getters = {
  getMixinResults: (state) => state.mixinResults,
  getTimeConstraints: (state) => state.timeConstraints,
  getTimeConstraintsHighlight: (state) => state.timeConstraintsHighlight,
};

const mutations = {
    setResultMixin(state, data) {
      state.mixinResults.body = data.body;
      state.mixinResults.status = data.status;
      state.mixinResults.fetched_patients = data.fetched_patients;
      state.mixinResults.search_request_id = data.search_request_id;
      state.mixinResults.fetched_patients = {"id": null, "highlight": null, "results": []};
    },
    setSpecificKeyResultMixin(state, data){
      state.mixinResults[data.key] = data.value;
    },
    setDocumentCountResultMixin(state, data){
      state.mixinResults.count_result.authorized_documents = data;
    },
    setPatientCountResultMixin(state, data){
      state.mixinResults.count_result.authorized_patients = data;
    },
    addNewResultStructure(state, data){
      state.mixinResults.results_structure.push(data);
    },
    updateResultStructure(state, data){
      if (state.mixinResults?.results_structure?.[data.patientIndex]) {
         state.mixinResults.results_structure[data.patientIndex].data = data.innerHits;
      }
    },
    updateDisplayMoreChoice(state, index){
      state.mixinResults.results_structure[index].data.display_more = !state.mixinResults.results_structure[index].data.display_more;
    },
    addNewFetchedPatients(state, data){
      state.mixinResults.fetched_patients.results.push(...data);
    },
    resetDefaultState(state) {
      Object.assign(state, getDefaultState());
    },
    setTimeConstraints(state, data){
      state.timeConstraints = data;
    },
    setTimeConstraintsHighlight(state, data){
      state.timeConstraintsHighlight = data;
    },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};