/* eslint-disable */
import Vue from 'vue';
import App from './App.vue';
import router from './router/router.index';
import moment from 'moment';
import inViewport from '@/directives/inViewport';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'date-fns';

import vuetify from './plugins/vuetify';
import VirtualList from "vue-virtual-scroll-list/src";
import VueWordCloud from 'vuewordcloud';
import store from './store';
import VueClipboard from 'vue-clipboard2';
import './assets/style.css';
import {hasPerm} from "@/utils/permissions";
import {IsAdminPerm, Perm} from "@/enums/permissions";

const TextInput = () => import('@/components/commons/design/molecules/TextInput.vue');
const DateInput = () => import('@/components/commons/design/molecules/DateInput.vue');
const TextareaInput = () => import('@/components/commons/design/molecules/TextareaInput.vue');
const Icon = () => import('@/components/commons/design/atoms/Icon.vue');
const Badge = () => import('@/components/commons/design/molecules/Badge.vue');
const RadioButton = () => import('@/components/commons/design/molecules/RadioButton.vue');
const DropDown = () => import('@/components/commons/design/molecules/DropDown.vue');
const Filters = () => import('@/components/commons/design/molecules/Filters/Filters.vue');
const CustomDataTable = () => import('@/components/commons/table/CustomDataTable.vue');
const TabList = () => import('@/components/commons/design/molecules/TabList.vue');
const DialogBox = () => import('@/components/commons/design/molecules/DialogBox.vue');
const Overlay = () => import('@/components/commons/design/molecules/Overlay.vue');
const ActionMenu = () => import('@/components/commons/design/molecules/ActionMenu.vue')

Vue.use(VueClipboard);
Vue.component(VueWordCloud.name, VueWordCloud);
Vue.component(VirtualList.name, VirtualList)
Vue.component('TextInput', TextInput);
Vue.component('DateInput', DateInput);
Vue.component('TextareaInput', TextareaInput);
Vue.component('Icon', Icon);
Vue.component('Badge', Badge);
Vue.component('DropDown', DropDown);
Vue.component('RadioButton', RadioButton);
Vue.component('Filters', Filters);
Vue.component('CustomDataTable', CustomDataTable);
Vue.component('TabList', TabList);
Vue.component('DialogBox', DialogBox);
Vue.component('ActionMenu', ActionMenu);
Vue.component('Overlay', Overlay);
Vue.directive('in-viewport', inViewport);
Vue.config.productionTip = false;

export const bus = new Vue();

Vue.filter("date", (date, pattern = "DD/MM/YYYY") => {
  if (date) {
    return moment(date).format(pattern);
  } else if (date === null || date === "") {
    return  "-";
  }
});

Vue.filter("datetime", (date, pattern = "DD/MM/YYYY HH:mm") => {
  if (date) {
    return moment(date).format(pattern);
  } else if (date === null || date === "") {
      return  "-";
  }
});

Vue.filter("date_str", (date, pattern = "DD MMM YYYY") => {
  if (date) {
    return moment(date).locale("fr").format(pattern);
  } else if (date === null || date === "") {
      return  "-";
  }
});

Vue.filter("date_str_concat", (date) => {
  if (date) {
    return moment(date).locale("fr").format("D MMM");
  } else if (date === null || date === "") {
      return  "-";
  }
});

Vue.filter("date_year", (date) => {
    if (date) {
        return moment(date).format("YYYY");
    } else if (date === null || date === "") {
        return  "-";
    }
});

Vue.filter("splitNumber", (value) => {
  if (value > 0) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  return value;
});

Vue.filter("firstUpper", (str) => str?.charAt(0).toUpperCase() + str?.slice(1));

Vue.filter("empty", (value) => {
  return (value === null || value === "") ? "-" : value;
});

Vue.filter("trimExtension", (value) => {
    return value.substring(0, value.lastIndexOf('.'));
});

Vue.mixin({
    data() {
        return {
            perms: Perm,
            isAdminPerm: IsAdminPerm
        };
    },

    methods: {
        can: (perm) => hasPerm(perm),
        t: s => s
    }
});

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
}).$mount('#app');
