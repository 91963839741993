<template>
  <login-template
    title="Connexion"
    :logo="getLogo"
    :loading="loading"
    :error-message="errorMsg"
    :form="form"
    :description="description"
    @submit="login"
  />
</template>

<script>
import i18n from '@/mixins/i18n';
import AuthEDS from '@/services/auth/auth.eds';
import {MODE, SUITE} from '@/config';
import formRules from '@/mixins/rules/formRules';
import {Errors} from "@/enums/response_errors";
import LoginTemplate from '@/views/auth/LoginTemplate';
import {APPS} from "@/config";

export default {
  name: 'Login',
  components: { LoginTemplate },
  mixins: [i18n, formRules],

  data() {
    return {
      form: {
        username: '',
        password: ''
      },

      loading: false,
      errorMsg: null
    };
  },

  computed: {
    description() {
      if (process.env.VUE_APP_MODE === MODE.EDS && this.$store.getters["customApp/getCustomSettings"].login_page === SUITE.TRANSPARENCY_STANDALONE.name) {
        return APPS.STANDALONE.description;
      } else {
        return APPS[process.env.VUE_APP_MODE].description;
      }
    },

    getLogo() {
      let logo = "";
      switch (process.env.VUE_APP_MODE) {
        case MODE.CARE:
          logo = require('@/assets/img/logo/ResearchHub-Care.svg');
          break;
        case MODE.EDS:
          if (this.$store.getters["customApp/getCustomSettings"].login_page === SUITE.TRANSPARENCY_STANDALONE.name) {
            logo = require('@/assets/img/logo/Transparency.svg');
          } else {
            logo = require('@/assets/img/logo/ResearchHub.svg');
          }
          break;
        case MODE.GDDI:
          logo = require('@/assets/img/logo/Transparency.svg');
          break;
        case MODE.RESEARCH:
          logo =  require('@/assets/img/logo/ResearchHub-Workspace.svg');
          break;
      }
      return logo;
    }
  },

  methods: {
    login() {
      this.loading = true;
      AuthEDS.identification(this.form.username, this.form.password).then(
          response => {
            const is_two_factor_setup = response.data.two_factor;
            if (is_two_factor_setup) {
              this.$router.push({name: 'OTPVerify'});
            } else {
              this.$router.push({name: 'OTPSetup'});
            }
          },
          error => {
            if (error.response.status === 401 && error.response.data.message !== Errors.NO_ACTIVE_ACCOUNT.text) {
              this.$store.commit('snackbar/errorSnackbar', {message: error.response.data.message});
            } else {
              this.$store.commit('snackbar/apiErrorSnackbar', error.response.data);
            }
            if (error.response.status === 401 || error.response.status === 403) {
              this.errorMsg = error.response.data.message;
            } else {
              this.errorMsg = this.t("Une erreur est survenue.");
            }
          }
      ).finally(() => this.loading = false);
    }
  }
};
</script>
