import axios from "axios";
import authHeader from "@/services/auth/auth.header";

export const DWH_API_URL = process.env.VUE_APP_DWH_API_URL;
export const DWH_STATIC_URL = process.env.VUE_APP_DWH_API_URL + '/static';

export const authRequest = axios.create({
    baseURL: DWH_API_URL,
});

export class ReadOnlyApi {
    constructor(resource) {
        this.resource = resource;
        this.url_list = `/${this.resource}/`;
    }

    url_detail(pk) {
        return `/${this.resource}/${pk}/`;
    }


    async list(params = {}, cancelToken = null) {
        return await authRequest.get(this.url_list, {headers: authHeader(), params: params, cancelToken: cancelToken});
    }

    async retrieve(pk, params = {}) {
        return await authRequest.get(this.url_detail(pk), {headers: authHeader(), params: params});
    }

    async export(data = {}) {
        const url = this.url_list + 'export/';
        return await authRequest.post(url, data, {headers: authHeader(), responseType: "blob"});
    }

    async preview_export(base_pk, data = {}, params) {
        const url = this.url_list + 'preview_export/';
        return await authRequest.post(url, data, {headers: authHeader(), params: params});
    }

    async fields(id, params= {}) {
        const url = this.url_list + 'fields/';
        return await authRequest.get(url, {headers: authHeader(), params: params});
    }
}


export class Api extends ReadOnlyApi {
    async create(obj = {}, params) {
        return await authRequest.post(this.url_list, obj, {headers: authHeader(), params: params});
    }

    async generate(id, obj = {}) {
        return await authRequest.post(this.url_detail(id), obj, {headers: authHeader()});
    }

    async partial_update(id, obj) {
        return await authRequest.patch(this.url_detail(id), obj, {headers: authHeader()});
    }

    async update(id, obj, params) {
        return await authRequest.put(this.url_detail(id), obj, {headers: authHeader(), params: params});
    }

    async destroy(id) {
        return await authRequest.delete(this.url_detail(id), {headers: authHeader()});
    }

    async bulk_update(data) {
        const url = this.url_list + 'bulk_update/';
        return await authRequest.put(url, data, {headers: authHeader()});
    }

    async bulk_create(data = []) {
        const url = this.url_list + 'bulk_create/';
        return await authRequest.post(url, data, {headers: authHeader()});
    }

    async export(data = {}, params = {}) {
        const url = this.url_list + 'export/';
        return await authRequest.post(url, data, {headers: authHeader(), responseType: "blob", params: params});
    }
}


export class NestedApi {
    constructor(base, resource) {
        this.base = base;
        this.resource = resource;
    }


    url_list(base_pk) {
        return `/${this.base}/${base_pk}/${this.resource}/`;
    }


    url_detail(base_pk, resource_pk) {
        return `/${this.base}/${base_pk}/${this.resource}/${resource_pk}/`;
    }


    async list(base_pk, params = {}) {
        return await authRequest.get(this.url_list(base_pk), {headers: authHeader(), params: params});
    }


    async retrieve(base_pk, resource_pk, params = {}) {
        return await authRequest.get(this.url_detail(base_pk, resource_pk), {headers: authHeader(), params: params});
    }


    async create(base_pk, obj = {}, params = {}) {
        return await authRequest.post(this.url_list(base_pk), obj, {headers: authHeader(), params: params});
    }


    async update(base_pk, resource_pk, obj) {
        return await authRequest.put(this.url_detail(base_pk, resource_pk), obj, {headers: authHeader()});
    }


    async patch(base_pk, resource_pk, obj) {
        return await authRequest.patch(this.url_detail(base_pk, resource_pk), obj, {headers: authHeader()});
    }


    async destroy(base_pk, resource_pk) {
        return await authRequest.delete(this.url_detail(base_pk, resource_pk), {headers: authHeader()});
    }


    async bulk_create(base_pk, data = []) {
        return await authRequest.post(this.url_list(base_pk) + 'bulk_create/', data, {headers: authHeader()});
    }


    async bulk_destroy(base_pk, data = []) {
        const url = this.url_list(base_pk) + 'bulk_destroy/';
        return await authRequest.post(url, data, {headers: authHeader()});
    }


    async getWeightedConcepts(base_pk, params = {}) {
        return await authRequest.get(this.url_list(base_pk) + 'get_weighted_concepts/', {headers: authHeader(), params: params});
    }

    async export(base_pk, data = {}) {
        const url = this.url_list(base_pk) + 'export/';
        return await authRequest.post(url, data, {headers: authHeader(), responseType: "blob"});
    }

    async preview_export(base_pk, data = {}) {
        const url = this.url_list(base_pk) + 'preview_export/';
        return await authRequest.post(url, data, {headers: authHeader()});
    }

    async download(base_pk, params = {}) {
        const url = this.url_list(base_pk);
        return await authRequest.get(url, {headers: authHeader(), params: params, responseType: "blob"});
    }

    async fields(base_pk, params= {}) {
        const url = this.url_list(base_pk) + 'fields/';
        return await authRequest.get(url, {headers: authHeader(), params: params});
    }

    async cancelExport(base_pk, params = {}) {
        const url = this.url_list(base_pk);
        return await authRequest.get(url, {headers: authHeader(), params: params, responseType: "blob"});
    }

    async cancel(base_pk, params = {}) {
        const url = this.url_list(base_pk) + 'cancel/';
        return await authRequest.get(url, {headers: authHeader(), params: params});
    }
}


export class DoubleNestedApi {
    constructor(base, base2, resource) {
        this.base = base;
        this.base2 = base2;
        this.resource = resource;
    }

    url_list(base_pk, base2_pk) {
        return `/${this.base}/${base_pk}/${this.base2}/${base2_pk}/${this.resource}/`;
    }

    url_detail(base_pk, base2_pk, resource_pk) {
        return `/${this.base}/${base_pk}/${this.base2}/${base2_pk}/${this.resource}/${resource_pk}/`;
    }

    async list(base_pk, base2_pk, params = {}) {
        return await authRequest.get(this.url_list(base_pk, base2_pk), {headers: authHeader(), params: params});
    }

    async export(base_pk, base2_pk, params = {}) {
        return await authRequest.get(this.url_list(base_pk, base2_pk), {headers: authHeader(), params: params, responseType: "blob"});
    }

    async preview_export(base_pk, base2_pk, params = {}) {
        return await authRequest.get(this.url_list(base_pk, base2_pk), {headers: authHeader(), params: params});
    }

    async retrieve(base_pk, base2_pk, resource_pk, params = {}) {
        return await authRequest.get(this.url_detail(base_pk, base2_pk, resource_pk), {headers: authHeader(), params: params});
    }

    async create(base_pk, base2_pk, obj = {}) {
        return await authRequest.post(this.url_list(base_pk, base2_pk), obj, {headers: authHeader()});
    }

    async update(base_pk, base2_pk, resource_pk, obj) {
        return await authRequest.put(this.url_detail(base_pk, base2_pk, resource_pk), obj, {headers: authHeader()});
    }

    async destroy(base_pk, base2_pk, resource_pk) {
        return await authRequest.delete(this.url_detail(base_pk, base2_pk, resource_pk), {headers: authHeader()});
    }

    async download(base_pk, base2_pk, params = {}) {
        const url = this.url_list(base_pk, base2_pk);
        return await authRequest.get(url, {headers: authHeader(), params: params, responseType: "blob"});
    }
}


export class UserApi extends Api {
    async me() {
        const url = this.url_list + 'me/';
        return await authRequest.get(url, {headers: authHeader()});
    }
    async update_profile_password(data={}) {
        const url = this.url_list + 'update_profile_password/';
        return await authRequest.put(url, data, {headers: authHeader()});
    }
}

export class Sde extends Api {
    async copy_sde(id, params= {}) {
        const url = this.url_detail(id) + 'copy_sde/';
        return await authRequest.get(url, {headers: authHeader(), params: params});
    }

    async import_from_json(data= {}) {
        const url = this.url_list + 'import_from_json/';
        return await authRequest.post(url, data, {headers: authHeader()});
    }

    async copy_category(data = {}) {
        const url = this.url_list + 'copy_category/';
        return await authRequest.post(url, data, { headers: authHeader() });
    }

    async from_file(pk, data) {
        const url = this.url_detail(pk) + 'import_questions_from_file/';
        return await authRequest.post(url, data, {headers: authHeader()});
    }
}

export class SdeQuestion extends Api {
    url(id, is_subquestion) {
        let url = this.url_list;
        if (id) {
            url += id + '/';
        }
        if (is_subquestion) {
            url += '?is_subquestion=' + is_subquestion;
        }
        return url;
    }

    async create(is_subquestion, question) {
        return await authRequest.post(this.url(null, is_subquestion), question,{headers: authHeader()});
    }

    async update(is_subquestion, question) {
        return await authRequest.put(this.url(question.id, is_subquestion), question, {headers: authHeader()});
    }

    async partial_update(is_subquestion, id, param) {
        return await authRequest.patch(this.url(id, is_subquestion), param, {headers: authHeader()});
    }

    async destroy(is_subquestion, id) {
        return await authRequest.delete(this.url(id, is_subquestion),{headers: authHeader()});
    }
}

export class CustomSettingsApi extends Api {
    async getSettings() {
        return await authRequest.get(this.url_list + 'settings/');
    }
}

export class CohortApi extends Api {
    async suggest(params = {}) {
        const url = this.url_list + 'suggest/';
        return await authRequest.get(url, {headers: authHeader(), params: params});
    }
}

export class SuggestApi {
    constructor() {
        this.base_url = `/suggest/`;
    }

    async elasticsearch(index, field, q, size = 5) {
        return await authRequest.get(this.base_url + 'elasticsearch/', {
            headers: authHeader(),
            params: {
                index: index, field: field, q: q, size: size
            }
        });
    }

    async list(params) {
        return await authRequest.get(this.base_url + 'database/', {
            headers: authHeader(),
            params: {
                app: params.app, model: params.model, field: params.field, search: params.search, size: params.size ? params.size : 5
            }
        });
    }
}

export class SearchApi {
    constructor() {
        this.base_url = `/search/`;
    }


    async fetch(data, params, cancelToken = null) {
        return await authRequest.post(this.base_url + 'fetch/', data, {headers: authHeader(), params: params, cancelToken: cancelToken});
    }

    async fetchPatients(data, params, cancelToken = null) {
        return await authRequest.post(this.base_url + 'fetch_patients/', data, {headers: authHeader(), params: params, cancelToken: cancelToken});
    }

    async fetchInnerHits(data, params, cancelToken = null) {
        return await authRequest.post(this.base_url + 'fetch_inner_hits/', data, {headers: authHeader(), params: params, cancelToken: cancelToken});
    }


    async count(data, cancelToken = null) {
        return await authRequest.post(this.base_url + 'count/', data, {headers: authHeader(), cancelToken: cancelToken});
    }

    async feed_cohort(data) {
        return await authRequest.post(this.base_url + 'feed_cohort/', data, {headers: authHeader()});
    }


    async patients(data) {
        return await authRequest.post(this.base_url + 'patients/', data, {headers: authHeader()});
    }


    async validate(data) {
        return await authRequest.post(this.base_url + 'validate/', data, {headers: authHeader()});
    }

}

export class DocumentSynonymsApi {

    async get(documentId, data) {
        return await authRequest.post('/documents/' + documentId + '/synonyms/', data, {headers: authHeader()});
    }

}

export class SearchRequestApi {
    constructor() {
        this.base_url = `/queries/search_request/`;
    }

    async history(params) {
        return await authRequest.get(this.base_url + 'history/', {headers: authHeader(), params: params});
    }

    async unsaved(id) {
        return await authRequest.delete(this.base_url + id + '/unsaved/', {headers: authHeader()});
    }

    async tab(params, id, tab) {
        return await authRequest.get(this.base_url  + id + '/' + tab + '/', {headers: authHeader(), params: params});
    }

    async list(params) {
        return await authRequest.get(this.base_url, {headers: authHeader(), params: params});
    }

    async fields(id, params= {}) {
        const url = this.base_url + 'fields/';
        return await authRequest.get(url, {headers: authHeader(), params: params});
    }

    async export(data = {}, params = {}) {
        const url = this.base_url + 'export/';
        return await authRequest.post(url, data, {headers: authHeader(), responseType: "blob", params: params});
    }
}

export class SavedRequestApi {
    constructor() {
        this.base_url = `/queries/saved_request/`;
    }

    async save(data) {
        return await authRequest.post(this.base_url, data, {headers: authHeader()});
    }

    async edit(id, data) {
        return await authRequest.patch(this.base_url + id + '/', data, {headers: authHeader()});
    }

    async overwrite(id, data) {
        return await authRequest.patch(this.base_url + id + '/', data, {headers: authHeader()});
    }

    async delete(id) {
        return await authRequest.delete(this.base_url + id + '/', {headers: authHeader()});
    }

    async list(params) {
        return await authRequest.get(this.base_url, {headers: authHeader(), params: params});
    }
}

export class ConceptApi {
    constructor() {
        this.base_url = `/thesaurus`;
    }

    async search(thesaurus_code, concept, params) {
        return await authRequest.get(this.base_url + '/thesaurus_data/', {
            headers: authHeader(),
            params: {
                thesaurus_code: thesaurus_code, concept: concept, ...params
            }
        });
    }

    async codes(type, concept, params) {
        return await authRequest.get(this.base_url + '/thesaurus_data/thesaurus_codes/', {
            headers: authHeader(),
            params: {
                type: type, concept: concept, ...params
            }
        });
    }

    async hierarchy(params) {
        return await authRequest.get(this.base_url + '/hierarchy/', {
            headers: authHeader(),
            params: params
        });
    }

    async related_to(params) {
        return await authRequest.get(this.base_url + '/thesaurus_data/related_to/', {
            headers: authHeader(),
            params: params
        });
    }

    async tree(params) {
        return await authRequest.get(this.base_url + '/tree/', {
            headers: authHeader(),
            params: params
        });
    }

    async tree_children(id, params) {
        return await authRequest.get(this.base_url + '/tree/' + id + '/', {
            headers: authHeader(),
            params: params
        });
    }
}

export class PatientApi extends ReadOnlyApi {
    async get_customs(params = {}) {
        const url = this.url_list + 'customs/';
        return await authRequest.get(url, {headers: authHeader(), params: params});
    }

    async from_file(data) {
        const url = this.url_list + 'from_file/';
        return await authRequest.post(url, data, {headers: authHeader()});
    }
}

export class PatientRecordApi extends NestedApi {
    async _meta(base_pk, params = {}) {
        return await authRequest.get(this.url_list(base_pk) + '_meta/', {headers: authHeader(), params: params});
    }

    async _search(base_pk, params = {}) {
        return await authRequest.get(this.url_list(base_pk) + '_search/', {headers: authHeader(), params: params});
    }

    async _searchEngine(base_pk, data, params = {}) {
        return await authRequest.post(this.url_list(base_pk) + '_search/', data, {headers: authHeader(), params: params});
    }

    async highlight(base_pk, resource_pk, data) {
        return await authRequest.post(this.url_detail(base_pk, resource_pk) + 'highlight/', data,{headers: authHeader()});
    }
}

export class CohortResultApi extends NestedApi {
    async _contains(base_pk, params = {}) {
        return await authRequest.get(this.url_list(base_pk) + '_contains/', {headers: authHeader(), params: params});
    }
}

export class AggregatedPatientApi extends ReadOnlyApi {

    async stats(params = {}) {
        const url = this.url_list + 'stats/';
        return await authRequest.get(url, {headers: authHeader(), params: params});
    }

    async graph(params = {}) {
        const url = this.url_list + 'graph/';
        return await authRequest.get(url, {headers: authHeader(), params: params});
    }
}

export class OppositionApi extends Api {
    async revoke(params={}) {
        return await authRequest.delete(this.url_list + 'revoke/', {headers: authHeader(), params: params});
    }
    async from_file(data) {
        return await authRequest.post(this.url_list + 'from_file/', data, {headers: authHeader()});
    }
    async bulk(data) {
        const url = this.url_list + 'bulk/';
        return await authRequest.patch(url, data, {headers: authHeader()});
    }
}


export class WorkspaceApi extends Api {
    async bulk(pk, data) {
        const url = this.url_detail(pk) + 'patients/bulk/';
        return await authRequest.post(url, data, {headers: authHeader()});
    }
}
