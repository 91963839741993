<template>
  <login-template title="Authentification à deux facteurs" :logo="logo"
                  logo-width="159" otp-setup
  />
</template>

<script>
import LoginTemplate from '@/views/auth/LoginTemplate';
import {MODE, SUITE} from '@/config';

export default {
  name: 'OTPSetup',
  components: {
    LoginTemplate,
  },

  data() {
    return {

    };
  },

  computed: {
    logo () {
      switch (process.env.VUE_APP_MODE) {
        case MODE.CARE:
          return require('@/assets/img/logo/ResearchHub-Care.svg');
        case MODE.EDS:
          if (this.$store.getters["customApp/getCustomSettings"].login_page === SUITE.TRANSPARENCY_STANDALONE.name) {
            return require('@/assets/img/logo/Transparency.svg');
          } else {
            return  require('@/assets/img/logo/ResearchHub.svg');
          }
        case MODE.GDDI:
          return require('@/assets/img/logo/Transparency.svg');
        case MODE.RESEARCH:
          return require('@/assets/img/logo/ResearchHub-Workspace.svg');
        default:
          return '';
      }
    }
  }
};
</script>
