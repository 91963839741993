import {
  AggregatedPatientApi,
  Api,
  CohortApi,
  CohortResultApi,
  ConceptApi,
  CustomSettingsApi,
  PatientRecordApi,
  DoubleNestedApi,
  NestedApi,
  OppositionApi,
  PatientApi,
  ReadOnlyApi,
  SavedRequestApi,
  SdeQuestion,
  SearchApi,
  SearchRequestApi,
  DocumentSynonymsApi,
  SuggestApi,
  UserApi,
  WorkspaceApi,
  Sde,
} from "./service";

import { DocumentStatsApi } from "@/services/stats/documents";
import { CohortsStatsApi } from "@/services/stats/cohorts";
import { SearchStatsApi } from "@/services/stats/search";
import { OldAppStatsApi } from "@/services/stats/old_app";
import { LogsApi } from "@/services/logs";
import { UsersStatsApi } from "@/services/stats/users";
import { PatientsStatsApi } from "@/services/stats/patients";
import { WorkspacesSessionsApi } from "@/services/workspaces/sessions";
import { WorkspacesImportApi } from "@/services/workspaces/import";
import { ExportChartApi, ExportDetailApi } from "@/services/exports";

export const Settings = new ReadOnlyApi("settings");
export const CustomizationsSettings = new CustomSettingsApi("customizations");
export const Version = new ReadOnlyApi("settings/version");
export const Users = new UserApi("users");
export const Units = new ReadOnlyApi("units");
export const Departments = new Api("departments");
export const Sites = new Api("sites");
export const Instances = new Api("instances");

export const Sources = new Api("sources");
export const Thesauri = new Api("thesaurus/thesauri");
export const ThesaurusRelationship = new Api(
  "thesaurus/thesaurus_relationship"
);
export const ParametersType = new Api("customizations/parameters_type");
export const Parameters = new Api("parameters");
export const MovementsType = new Api("stays/movement_type");
export const ConceptRelationship = new Api("thesaurus/concept_relationship");

/* Search */
export const Search = new SearchApi();
export const Suggest = new SuggestApi();
export const SearchRequest = new SearchRequestApi();
export const SavedRequest = new SavedRequestApi();
export const LibrarySearchRequest = new Api("queries/library_search_request");
export const DocumentSynonyms = new DocumentSynonymsApi();

/* Data */
export const Concept = new ConceptApi();
export const ConceptTree = new Api("thesaurus/tree");

/* Cohort */
export const Cohorts = new CohortApi("cohorts");
export const CohortResults = new CohortResultApi("cohorts", "patients");
export const CohortMerge = new NestedApi("cohorts", "merge");
export const CohortResultsComments = new DoubleNestedApi(
  "cohorts",
  "patients",
  "comments"
);
export const CohortExportItems = new NestedApi("cohorts", "export_items");
export const CohortExport = new NestedApi("cohorts", "export");
export const CohortRawExport = new NestedApi("cohorts", "raw_export");
export const CohortUsers = new NestedApi("cohorts", "users");
export const CohortPeriodicAlim = new NestedApi("cohorts", "self_feed_rules");
export const CohortAlimRequests = new NestedApi("cohorts", "sources");

/* SDE */
export const SDE = new Sde("sde/sde");
export const SDEForm = new NestedApi("sde", "form");
export const SDEQuestion = new SdeQuestion("sde/questions");
export const SDECategory = new Api("sde/categories");
export const SDESummary = new Api("sde/summary");
export const SDEQuestionAnswer = new Api("sde/answers");
export const SDEPatientAnswer = new Api("sde/patient_answer");
export const SDEPatientSearch = new NestedApi("patients", "fetch");
export const SDEEvent = new Api("sde/events");
export const SDECohortStatus = new Api("sde/cohort/status");
export const SDEFullAutocomplete = new NestedApi("sde/sde", "full_autocomplete");

/* Patients list */
export const Patients = new PatientApi("patients");

/* Ipps list */
export const IppsHistory = new ReadOnlyApi("ipp_history");

/* Patient record */
export const Stay = new PatientRecordApi('patients', 'stays');
export const Movement = new NestedApi('patients', 'movements');
export const Documents = new PatientRecordApi('patients', 'documents');
export const EFRSearch = new PatientRecordApi('patients', 'efr');
export const BiologySearch = new PatientRecordApi('patients', 'biology/data');
export const PMSISearch = new PatientRecordApi('patients', 'pmsi');
export const PrescriptionsSearch = new PatientRecordApi('patients', 'prescriptions');
export const MovementsSearch = new PatientRecordApi('patients', 'movements');
export const PatientsQueries = new Api('logs/queries/patients');

export const BiologyDoc = new NestedApi('patients', 'biology/doc');
export const BiologyData = new NestedApi('patients', 'biology/data');
export const EFR = new NestedApi('patients', 'efr');
export const PatientCohorts = new NestedApi('patients', 'cohorts');
export const Phenotypes = new NestedApi('patients', 'phenotypes');
export const Prescriptions = new NestedApi('patients', 'prescriptions');
export const PhenotypesDocument = new DoubleNestedApi('patients', 'phenotypes', 'documents');
export const PMSI = new NestedApi('patients', 'pmsi');

/* Admin */
export const AdminGroups = new Api("groups");
export const AdminExhaustiveness = new Api("warehouse/exhaustiveness");
export const IndexationLogs = new Api("opensearch/scripts");
export const AdminTasks = new Api("kpis/task_results");
export const AdminCommands = new Api("commands/commands");
export const AdminFeatureFlags = new Api("customizations/feature_flags");
export const ConnectionLogs = new Api("admin/history");

/* Opposition */
export const Opposition = new OppositionApi("opposition");
export const OpposedPatients = new Api("opposition-gddi");

/* Arno */
export const Tokens = new Api("arno/tokens");
export const Paragraphs = new Api("arno/custom_paragraph");

/* Stats */
export const DocumentsStats = new DocumentStatsApi();
export const CohortsStats = new CohortsStatsApi();
export const SearchStats = new SearchStatsApi();
export const OldAppStats = new OldAppStatsApi();
export const UsersStats = new UsersStatsApi();
export const PatientStats = new PatientsStatsApi();
export const StaysStats = new Api("warehouse/stays");
export const StaysStatsYears = new Api("warehouse/stays/years");
export const DepartmentsStats = new Api("warehouse/departments");
export const DepartmentsStatsYears = new Api("warehouse/departments/years");
export const UnitsStats = new Api("warehouse/units");
export const UnitsStatsYears = new Api("warehouse/units/years");
export const MovementsStats = new Api("warehouse/movements");

/* Logs */
export const Logs = new LogsApi();

/* Cohorts */
export const CohortAggregatedDataBiology = new NestedApi('cohorts', 'aggregated_biology');
export const CohortAggregatedDataEfr = new NestedApi('cohorts', 'aggregated_efr');
export const CohortAggregatedPMSI = new NestedApi('cohorts', 'aggregated_pmsi');
export const CohortAggregatedDataPrescription = new NestedApi('cohorts', 'aggregated_prescription');
export const AggregatedPhenotype = new ReadOnlyApi('aggregated_phenotypes');
export const AggregatedPatient = new AggregatedPatientApi('aggregated_patient');
export const CohortExports = new Api('export_logs');
export const CohortDocExports = new NestedApi('cohorts', 'document_content_export');
export const CohortDownloadExport = new NestedApi('export_logs', 'download');
export const CohortCancelExport = new NestedApi('export_logs', 'cancel');


/* [Workspaces] User */
export const Workspaces = new WorkspaceApi("workspaces/workspaces");
export const WorkspacesRequestUsers = new NestedApi(
  "workspaces/workspaces",
  "request_user"
);
export const WorkspacesComments = new NestedApi(
  "workspaces/workspaces",
  "comments"
);
export const WorkspacesSessions = new WorkspacesSessionsApi(
  "workspaces/sessions"
);
export const WorkspacesImport = new WorkspacesImportApi("workspaces/import");
export const WorkspacesImportCohort = new NestedApi(
  "workspaces/workspaces",
  "from_cohort"
);
export const WorkspacesPatients = new NestedApi(
  "workspaces/workspaces",
  "patients"
);
export const WorkspacesUsers = new NestedApi("workspaces/workspaces", "users");
export const WorkspacesQuestions = new Api("workspaces/questions");
export const WorkspacesPatientsNotInformed = new NestedApi(
  "workspaces/workspaces",
  "patients/delete_not_informed"
);
export const WorkspacesFiles = new NestedApi("workspaces/workspaces", "files");
export const WorkspacesDownloadFiles = new DoubleNestedApi(
  "workspaces/workspaces",
  "files",
  "download"
);
export const WorkspaceFormQuestionVisibility = new NestedApi(
  "workspaces/workspaces",
  "form_visibility"
);

/* [Workspaces] Admin */
export const AdminWorkspacesPatients = new Api("workspaces/admin/tables");
export const AdminWksVMs = new Api("workspaces/admin/vms");
export const AdminWksApps = new Api("workspaces/admin/apps");
export const AdminWksVariables = new Api("workspaces/admin/variables");
export const AdminWksConfigs = new Api("workspaces/admin/configs");
export const AdminWksUsers = new Api("workspaces/admin/users");

/* Analysis */
export const ExportList = new Api("exports/list");
export const ExportDetail = new ExportDetailApi("exports/detail");
export const ExportChart = new ExportChartApi("exports/charts");
export const IntegratedApps = new ReadOnlyApi("integrated-apps");
export const ExportLogs = new Api("exports/admin/history");
/* Tips */
export const Tips = new ReadOnlyApi("tips");

/* Feedbacks */
export const FeedbackPseudoAnonymization = new Api(
  "feedback_pseudo_anonymization"
);
export const FeedbackNPS = new Api("warehouse/nps");

/* DrAnonymous */
export const FileToAnonymous = new Api("exports/list");
export const ExportAnonymous = new Api("exports/to-anonymous");
