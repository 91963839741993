<template>
  <div v-if="!loading" class="mt-8">
    <v-row>
      <v-col>
        <span class="text-h5 font-demi-bold">
          {{ patient.first_name }} {{ patient.last_name ? patient.last_name.toUpperCase() : '' }}
        </span>
        <span v-if="patient.maiden_name" class="text-subtitle-1">
          {{ genderLetter(patient.sex) === 'F' ? t('née') : t('né') }} {{ patient.maiden_name.toUpperCase() }}
        </span>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="auto" class="mr-10">
        <div class="text-body-2 font-demi-bold">
          {{ t('Sexe') }}
        </div>
        <div class="basics-80">
          {{ genderWord(patient.sex) }}
        </div>
      </v-col>
      <v-col v-if="patient.birth_date" cols="auto" class="mr-10">
        <div class="text-body-2 font-demi-bold">
          {{ t('Naissance') }}
        </div>
        <div class="basics-80">
          {{ patient.birth_date | date }}
        </div>
      </v-col>
      <v-col v-if="patient.death_date" cols="auto" class="mr-10">
        <div class="text-body-2 font-demi-bold">
          {{ t('Décès') }}
        </div>
        <div class="basics-80">
          {{ patient.death_date | date }} {{ t('à') }} {{ patient.age_death }} {{ t('ans') }}
        </div>
      </v-col>
    </v-row>

    <custom-data-table
      :loading="loadingIpps"
      :items="itemsIpps"
      :headers="headersIpps"
      :nb-items-per-page="20"
      :options.sync="options"
      hide-default-footer
    />

    <v-row class="my-5">
      <v-col cols="12" class="pb-1 text-subtitle-1 font-demi-bold">
        {{ t('Contact') }}
      </v-col>
      <v-col v-if="patient.phone_number" cols="auto" class="mr-10">
        <div class="text-body-2 font-demi-bold">
          {{ t('Téléphone') }}
        </div>
        <div class="basics-80">
          {{ patient.phone_number }}
        </div>
      </v-col>
      <v-col v-if="patient.email" cols="auto" class="mr-10">
        <div class="text-body-2 font-demi-bold">
          {{ t('Email') }}
        </div>
        <div class="basics-80">
          {{ patient.email }}
        </div>
      </v-col>
      <v-col v-if="patient.residence_country" cols="auto" class="mr-10">
        <div class="text-body-2 font-demi-bold">
          {{ t('Pays') }}
        </div>
        <div class="basics-80">
          {{ patient.residence_country }}
        </div>
      </v-col>
      <v-col v-if="patient.residence_city" cols="auto" class="mr-10">
        <div class="text-body-2 font-demi-bold">
          {{ t('Ville') }}
        </div>
        <div class="basics-80">
          {{ patient.residence_city }}
        </div>
      </v-col>
      <v-col v-if="patient.residence_address" cols="auto" class="mr-10">
        <div class="text-body-2 font-demi-bold">
          {{ t('Adresse') }}
        </div>
        <div class="basics-80">
          {{ patient.residence_address }}
        </div>
      </v-col>
      <v-col v-if="patient.zip_code" cols="auto" class="mr-10">
        <div class="text-body-2 font-demi-bold">
          {{ t('Code postal') }}
        </div>
        <div class="basics-80">
          {{ patient.zip_code }}
        </div>
      </v-col>
    </v-row>

    <v-divider />

    <v-row class="my-5">
      <v-col cols="12" class="pb-1 text-subtitle-1 font-demi-bold">
        {{ t('Projets') }}
      </v-col>
      <v-col>
        <div>
          <div v-if="!loadingWorkspaces">
            <v-simple-table v-if="workspaces.length > 0">
              <template #default>
                <thead>
                  <tr>
                    <th>
                      {{ t('Titre') }}
                    </th>
                    <th>
                      {{ t('PID') }}
                    </th>
                    <th>
                      {{ t('Opposé au projet') }}
                    </th>
                    <th>
                      {{ t('Informé pour le projet') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in workspaces" :key="item.workspace.id">
                    <td>{{ item.workspace.title }}</td>
                    <td>{{ item.patient.patient_pid }}</td>
                    <td>
                      <span v-if="!item.opposition_status || item.opposition_status === OppositionStatus.NOT_OPPOSED.value" class="d-inline-flex">
                        {{ t('Non') }}
                        <a class="primary-main my-auto" @click="getUpdateInformation(item, false)">
                          <icon name="pencil" size="16" class="ml-2" />
                        </a>
                      </span>
                      <span v-else class="mr-2">
                        {{ t('Enregistrée') }} {{ t('le') }} {{ item.opposition_date | date }}
                        <span v-if="patient.opposition.source === 'drwh' && patient.opposition_register_by">
                          {{ t('par') }} {{ item.opposition_register_by.username }} ({{ item.opposition_register_by.first_name }}
                          {{ item.opposition_register_by.last_name }}).
                        </span>
                        <span v-else> {{ t('sur la plateforme patient. ') }} </span>
                      </span>
                    </td>
                    <td>
                      <div class="d-inline-flex my-auto">
                        {{ item.informed_status === InformationStatus.NOT_INFORMED.value ? t('Non') : t('Oui') }}
                        <a class="my-auto" @click="getUpdateInformation(item, true)">
                          <icon name="pencil" size="16" class="ml-2" />
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div v-else class="basics-80 mt-1">
              <span>{{ t('Aucun projet de recherche.') }}</span>
            </div>
          </div>
          <v-skeleton-loader v-else type="article, actions" class="w-100" />
        </div>
      </v-col>
    </v-row>

    <v-divider />

    <v-row class="my-5">
      <v-col cols="12" class="pb-1 text-subtitle-1 font-demi-bold">
        {{ t("Droit d'opposition") }}
      </v-col>
      <v-col cols="auto" class="mr-10">
        <div class="text-body-2 font-demi-bold">
          {{ t('Identifiant EDS') }}
        </div>
        <div>
          <badge :text="patient.patient_pid ? patient.patient_pid : '---'" type="outlined" />
        </div>
      </v-col>
      <v-col v-if="patient.opposition.opposition_status && patient.opposition.opposition_status !== OppositionStatus.NOT_OPPOSED.value" cols="auto" class="mr-10">
        <div class="text-body-2 font-demi-bold">
          {{ t('Opposition enregistrée le') }}
        </div>
        <div>
          {{ patient.opposition.date | date }}
          <span v-if="patient.opposition.source === 'drwh' && patient.opposition.register_by">
            {{ t('par') }} {{ patient.opposition.register_by.username }}
            ({{ patient.opposition.register_by.first_name }} {{ patient.opposition.register_by.last_name }}).
          </span>
          <span v-else-if="patient.opposition.source === 'arno'"> {{ t('sur la plateforme patient. ') }} </span>
        </div>
      </v-col>
      <v-col v-if="!patient.opposition.opposition_status || patient.opposition.opposition_status === OppositionStatus.NOT_OPPOSED.value" cols="auto" class="mr-10 d-flex align-center">
        <v-btn depressed small color="primary" class="text-none" @click="edsOppositionDialog = true">
          {{ t("Enregistrer l'opposition") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-divider />

    <v-row class="my-5">
      <v-col>
        <div class="text-body-2 font-demi-bold">
          {{ t("Droit à l'information") }}
        </div>
        <div>
          <div class="mt-4">
            <span v-if="!loadingInformationStatus" :class="getInformationStatusColor(patient.opposition.informed_status)">
              {{ getInformationStatus(patient.opposition.informed_status) }}
              <span v-if="patient.opposition.informed_status === InformationStatus.INFORMED.value" class="success-pressed">
                {{ t('depuis le ') + moment(patient.opposition.informed_status_date).format('DD/MM/YYYY') }}
              </span>
            </span>
          </div>
          <div v-if="!loadingInformationStatus" class="mt-2">
            <v-tooltip v-if="patient.opposition.informed_status !== InformationStatus.INFORMED.value" right open-delay="200" content-class="arrow-left">
              <template #activator="{ on, attrs }">
                <v-btn v-bind="attrs"
                       small class="primary"
                       v-on="on" @click="updateInformationStatus(InformationStatus.INFORMED.value, patient.id)"
                >
                  {{ t("Informer") }}
                </v-btn>
              </template>
              {{ t("Informer de l’existence de la plateforme de transparence") }}
            </v-tooltip>
            <v-tooltip v-if="patient.opposition.informed_status === InformationStatus.INFORMED.value" right open-delay="200" content-class="arrow-left">
              <template #activator="{ on, attrs }">
                <v-btn v-bind="attrs"
                       small class="danger"
                       v-on="on"
                       @click="updateInformationStatus(InformationStatus.NOT_INFORMED.value, patient.id)"
                >
                  {{ t("Révoquer") }}
                </v-btn>
              </template>
              {{ t("Révoquer l’information de l’existence de la plateforme de transparence") }}
            </v-tooltip>
          </div>
          <v-progress-circular v-else indeterminate size="18" width="2" class="w-100" />
        </div>
      </v-col>
    </v-row>

    <v-divider />

    <v-row class="my-5">
      <v-col cols="12" class="pb-1 text-subtitle-1 font-demi-bold">
        {{ t("Génération d'un identifiant pour la plateforme de transparence") }}
      </v-col>
      <v-col>
        <div v-if="!transparencyToken">
          <v-btn small
                 class="mr-2 primary"
                 :loading="loadingToken"
                 @click="createToken"
          >
            {{ t("Générer le token") }}
          </v-btn>
          <v-tooltip top open-delay="200" content-class="arrow-bottom">
            <template #activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <icon name="info-circle" color="basics-70" />
              </v-btn>
            </template>
            {{ t("Ce token permettra à la personne à qui vous l’envoyez d’accéder à la plateforme de transparence pendant 1 mois") }}
          </v-tooltip>
        </div>
        <div v-else>
          <div class="d-flex align-center">
            <span class="text-body-2 basics-80 text-truncate mr-2 max-w-300 d-inline-block">{{ transparencyToken.token }}</span>
            <v-btn small
                   class="mr-2 min-w-150"
                   :class="tokenButton.class"
                   @click="copyToken"
            >
              <span> {{ tokenButton.text }}</span>
            </v-btn>
          </div>
          <div class="mt-3 mb-6">
            <span class="mr-8">
              <span class="mr-2">{{ t('Date de création :') }}</span>
              <span class="basics-80">{{ $options.filters.date(transparencyToken.created_date) }}</span>
            </span>
            <span>
              <span class="mr-2">{{ t('Date d\'expiration :') }}</span>
              <span :class="expired ? 'danger-main' : 'basics-80'">{{ $options.filters.date(transparencyToken.revoked_date) }}</span>
            </span>
          </div>
          <v-btn small
                 class="primary mr-2"
                 :loading="loadingToken"
                 @click="recreateToken"
          >
            {{ t("Re-générer le token") }}
          </v-btn>
          <v-tooltip top open-delay="200" content-class="arrow-bottom">
            <template #activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <icon name="info-circle" color="basics-70" />
              </v-btn>
            </template>
            {{ t("Ce token permettra à la personne à qui vous l’envoyez d’accéder à la plateforme de transparence pendant 1 mois") }}
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
    <dialog-box :dialog="wksUpdateDialog" :loading="loadingUpdateWkInfo" width="400px"
                icon="megaphone" :title="t('Attention')" is-form-valid
                @close="wksUpdateDialog = false"
                @submit="updateWksInformation"
    >
      <template #default>
        <div>
          {{ t(wksUpdate.message) }}
        </div>
        <div class="mt-2">
          {{ t('Voulez vous confirmer ce choix ?') }}
        </div>
      </template>
    </dialog-box>

    <dialog-box :dialog="edsOppositionDialog" :loading="loadingEdsOpposition" width="400px"
                icon="megaphone" :title="t('Attention')" is-form-valid
                @close="edsOppositionDialog = false"
                @submit="registerEdsOpposition(patient.id)"
    >
      <template #default>
        <div>
          {{ t('Vous êtes sur le point d\'enregistrer l\'opposition d\'un patient à l\'ensemble de l’EDS.') }}
        </div>
        <div class="mt-2">
          {{ t('Voulez vous confirmer ce choix ?') }}
        </div>
      </template>
    </dialog-box>
  </div>
  <div v-else class="flex-grow-1 d-flex align-center justify-center">
    <v-progress-circular indeterminate color="primary" />
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';
import {AdminWorkspacesPatients, Opposition, Patients, Tokens, IppsHistory} from '@/services/api';
import gender from '@/mixins/gender';
import {InformationStatus} from '@/enums/workspaces';
import {Opposition as OppositionStatus} from '@/enums/opposition';
import moment from "moment";

export default {
  name: 'PatientDetail',
  mixins: [i18n, gender],
  props: {
    patientId: {
      type: Number,
      required: false,
    },

    updateInfo: Boolean
  },

  data () {
    return {
      moment,
      OppositionStatus,
      InformationStatus,
      patient: {
        opposition: {
          register_by: {}
        }
      },

      headersIpps: [
        { text: this.t("IPP"), align: "start", value: "ipp" },
        { text: this.t("Instance de l'IPP"), align: "start", value: "instance" },
        { text: this.t("Origine de l'IPP"), align: "start", value: "origin_code" },
        { text: this.t("IPP maître"), align: "start", value: "pretty_master" }
      ],

      loadingIpps: false,
      itemsIpps: [],
      loading: true,
      loadingWorkspaces: false,
      workspaces: [],
      edsOppositionDialog: false,
      wksUpdateDialog: false,
      wksUpdate: {},
      loadingInformationStatus: false,
      loadingEdsOpposition: false,
      loadingUpdateWkInfo: false,
      loadingToken: false,
      transparencyToken: null,
      options: {},
      tokenButton: {
        class: 'outlined',
        text: this.t('Copier le token')
      },

      expired: false
    };
  },

  watch: {
    updateInfo() {
      this.getPatient(this.patient.id);
    },

    options() {
      this.getIpps();
    }
  },

  mounted () {
    this.getPatient();
    this.getWorkspaces();
    this.getToken();
  },

  methods: {
    getInformationStatus (informed_status) {
      let status = Object.values(this.InformationStatus).find(status => status.value === informed_status);
      if (status) {
        return status.text;
      } else {
        return this.t('Non connu');
      }
    },

    getInformationStatusColor (informed_status) {
      switch (informed_status) {
        case this.InformationStatus.INFORMED.value:
          return 'success-pressed';
        case this.InformationStatus.NOT_INFORMED.value:
          return "warning-main";
        case this.InformationStatus.NO_CONTACT_INFORMATION.value:
          return 'danger-pressed';
        case this.InformationStatus.DECEASED.value:
          return 'basics-70';
      }
    },

    getPatient () {
      this.loading = true;
      Patients.retrieve(this.patientId).then(response => {
        this.patient = response.data;
      }).catch((error) => {
        this.$store.commit('snackbar/apiErrorSnackbar', error);
      }).finally(() => {
        this.loading = false;
      });
    },

    getWorkspaces () {
      this.loadingWorkspaces = true;
      AdminWorkspacesPatients.list({ patient: this.patientId }).then(response => {
        this.workspaces = response.data.results;
      }).catch((error) => {
        this.$store.commit('snackbar/apiErrorSnackbar', error);
      }).finally(() => {
        this.loadingWorkspaces = false;
      });
    },

    updateInformationStatus (informed_status, id) {
      this.loadingInformationStatus = true;
      Opposition.partial_update(id, { informed_status: informed_status }).then((response) => {
        this.$store.commit('snackbar/successSnackbar', { message: this.t('Modification de l\'information enregistré.') });
        this.patient.opposition.informed_status = response.data.patient.opposition.informed_status;
        this.patient.opposition.informed_status_date = response.data.patient.opposition.informed_status_date;
      }).catch((error) => {
        this.$store.commit('snackbar/apiErrorSnackbar', error);
      }).finally(() => {
        this.loadingInformationStatus = false;
      });
    },

    getUpdateInformation (workspace, notified) {
      this.wksUpdate = workspace;
      this.wksUpdate.notified = notified;
      this.wksUpdate.message = notified ? this.t('Vous êtes sur le point de modifier le statut d\'information d\'un patient.')
        : this.t('Vous êtes sur le point de modifier l\'opposion au projet d\'un patient.');
      this.wksUpdateDialog = true;
    },

    updateWksInformation () {
      this.loadingUpdateWkInfo = true;
      let param = null;
      if (this.wksUpdate.notified) {
        param = {informed_status: this.wksUpdate.informed_status === InformationStatus.INFORMED.value
              ? InformationStatus.NOT_INFORMED.value : InformationStatus.INFORMED.value};
      } else {
        param = { opposition_status: this.OppositionStatus.OPPOSED.value };
      }
      AdminWorkspacesPatients.update(this.wksUpdate.id, param).then((response) => {
        const index = this.workspaces.findIndex(wk => wk.id === this.wksUpdate.id);
        this.$set(this.workspaces, index, response.data);
        const message = this.wksUpdate.notified ? 'Statut d\'information modifié.' : 'Opposition enregistré.';
        this.$store.commit('snackbar/successSnackbar', { message: this.t(message) });
      }).catch((error) => {
        this.$store.commit('snackbar/apiErrorSnackbar', error);
      }).finally(() => {
        this.loadingUpdateWkInfo = false;
        this.wksUpdateDialog = false;
        this.wksUpdate = {};
      });
    },

    registerEdsOpposition (id) {
      this.loadingEdsOpposition = true;
      Opposition.partial_update(id, { opposition_status: this.OppositionStatus.OPPOSED.value }).then(response => {
        this.$set(this.patient, 'opposition', response.data.patient.opposition);
        this.$store.commit('snackbar/successSnackbar', { message: this.t('Opposition enregistrée.') });
      }).catch((error) => {
        this.$store.commit('snackbar/apiErrorSnackbar', error);
      }).finally(() => {
        this.loadingEdsOpposition = false;
        this.edsOppositionDialog = false;
      });
    },

    getToken() {
      this.loadingToken = true;
      Tokens.retrieve(this.patientId).then(response => {
        if (response.status === 200 && response.data.token) {
          this.transparencyToken = response.data;
        }
        if (!moment(response.data.revoked_date).isAfter()) {
          this.expired = true;
        }
      }).catch(() => {
        this.$store.commit('snackbar/errorSnackbar', {message: this.t('Erreur à la réception du token.')});
      }).finally(() => {
        this.loadingToken = false;
      });
    },

    createToken() {
      this.loadingToken = true;
      Tokens.generate(this.patient.id).then(response => {
        this.$store.commit('snackbar/successSnackbar', {message: this.t('Token créé.')});
        this.transparencyToken = response.data;
        if (this.expired) {
          this.expired = false;
        }
      }).catch(error => {
        if (error.response.status === 400) {
          this.$store.commit('snackbar/errorSnackbar', {message: this.t('Le token existe déjà.')});
        } else {
          this.$store.commit('snackbar/apiErrorSnackbar', error);
        }
      }).finally(() => {
        this.loadingToken = false;
      });
    },

    recreateToken() {
      this.loadingToken = true;
      Tokens.destroy(this.patient.id).then(() => {
        this.createToken();
      }).catch(() => {
        this.$store.commit('snackbar/errorSnackbar', {message: this.t('Erreur à la recréation du token.')});
      });
    },

    copyToken() {
      this.$copyText(this.transparencyToken.token).then(() => {
        this.$store.commit('snackbar/successSnackbar', {message: this.t("Token copié dans le presse-papiers.")});
        this.tokenButton.class = 'success';
        this.tokenButton.text = this.t('Copié');
        setTimeout(() => {
          this.tokenButton.class = 'outlined';
          this.tokenButton.text = this.t('Copier le token');
        }, 5000);
      }).catch((error) => {
        this.$store.commit('snackbar/apiErrorSnackbar', error);
      });
    },

    getIpps() {
      this.loadingIpps = true;
      let ordering = null;
      if (this.options.sortDesc) {
        ordering = this.options.sortDesc[0] ? '-' + this.options.sortBy[0] : this.options.sortBy[0];
      }
      const params = {
        patient_id: this.patientId,
        limit: this.options.itemsPerPage,
        offset: (this.options.page - 1) * this.options.itemsPerPage,
        ordering: ordering
      };
      IppsHistory.list(params)
        .then((response) => {
          this.itemsIpps = response.data.results;
          this.itemsIpps.forEach((element) => {
            element.pretty_master =  element.is_master ? "Oui" : "Non";
          });
        })
        .catch((error) => {
          this.$store.commit("snackbar/apiErrorSnackbar", error);
        }).finally(() => {
          this.loadingIpps = false;
      });
    },
  }
};
</script>

<style scoped>
  .min-w-150 {
    min-width: 150px !important;
  }
</style>