export default {
  inserted(el, binding) {

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const callback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (typeof binding.value === 'function') {
            const arg = binding.arg ? [binding.arg] : [];
            binding.value(entry, observer, ...arg);
          } else {
            console.error('Directive value is not a function');
          }
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(el);
  }
};