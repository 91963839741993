<template>
  <v-app id="app">
    <div class="d-flex flex-column min-h-screen overflow-hidden bg-basics-20">
      <nav-bar v-if="showNavbar" :show-empty-nav-bar="showEmptyNavBar" style="z-index:1000 !important;" />
      <v-main class="flex-grow-1" :class="{'p-0': !showFooter && !showNavbar}">
        <notifications v-if="displayNotifications && showNavbar" @dismiss="dismissNotification" />
        <bread-crumbs v-if="loggedIn && (showBreadcrumb || showTabs)" />
        <alert-message v-if="loggedIn && customSettings && customSettings.alert_enabled && displayAlertMessage && ready"
                       id="banner-alert-message" :class="alertMessagePosition"
        />
        <router-view v-if="ready" class="h-100" />
        <v-container v-else fill-height fluid>
          <v-row>
            <v-col class="text-center">
              <v-progress-circular size="40" indeterminate color="primary" />
            </v-col>
          </v-row>
        </v-container>
      </v-main>
      <div>
        <help-button-footer :class="{'login-position': !loggedIn}" />
      </div>
      <custom-footer v-if="showFooter" :class="logoPositionClass" />
      <snack-bar />
    </div>
  </v-app>
</template>

<script>
import i18n from '@/mixins/i18n';
import CustomFooter from '@/components/footer/CustomFooter';
import NavBar from '@/components/nav/NavBar';
import BreadCrumbs from '@/components/commons/BreadCrumbs';
import IdleTimer from '@/services/IdleTimer';
import { isLoggedIn } from '@/store/modules/auth';
import Router from '@/router/router.index';
import Notifications from '@/components/notifications/Notifications';
import { mapGetters } from 'vuex';
import webSockets from '@/mixins/webSockets';
import { MODE } from '@/config';
import SnackBar from '@/components/commons/design/molecules/SnackBar';
import HelpButtonFooter from '@/components/footer/HelpButtonFooter';
import AlertMessage from '@/components/commons/AlertMessage';
import {SUITE} from "@/config";
import {initializeFilters} from "@/enums/filters";

export default {
  name: 'App',
  components: { CustomFooter, NavBar, BreadCrumbs, Notifications, SnackBar, HelpButtonFooter, AlertMessage },
  mixins: [i18n, webSockets],

  data () {
    return {
      ready: false
    };
  },

  computed: {
    ...mapGetters({
      token: 'auth/accessToken',
      notifications: 'notifications/getNotifications',
      user: 'user/me',
      displayNotifications: 'notifications/displayNotifications',
      unReadNotifications: 'notifications/unReadNotifications',
      loggedIn: 'auth/loggedIn',
      customSettings: "customApp/getCustomSettings",
      displayAlertMessage: "customApp/getDisplayAlertMessage",
      breadCrumbs: 'breadcrumbs/getBreadCrumbs',
      tabs: 'breadcrumbs/getTabs',
      alertPosition: 'customApp/getAlertMessagePosition',
      logoShifted: 'logoShifted/logoShifted'
    }),

    showNavbar () {
      return !this.$route.meta.disableNavBar;
    },

    showFooter () {
      return !this.$route.meta.disableFooter;
    },

    showEmptyNavBar () {
      return this.$route.meta.showEmptyNavBar;
    },

    showBreadcrumb() {
      return this.breadCrumbs && this.breadCrumbs.length > 0;
    },

    showTabs() {
      return this.tabs && this.tabs.length > 0;
    },

    alertMessagePosition() {
      return this.loggedIn && this.customSettings && this.customSettings.alert_enabled && this.displayAlertMessage && this.ready &&
              this.$route.meta.alertMessagePosition ? {
        'alert-cohort-nav': this.alertPosition === 'navCohort',
        'alert-nav-info': this.alertPosition === 'navInfo',
        'alert-cohort-nav-min' : this.alertPosition === 'navCohortMin',
        'alert-nav-info-min' : this.alertPosition === 'navInfoMin',
        'alert-nav-sde' : this.alertPosition === 'navSde',
        'alert-without-nav': this.alertPosition === 'fullScreen',
        'alert-mt': this.alertPosition === 'topMarginOnly',
      } : this.alertPosition === 'fullScreen';
    },

    logoPositionClass() {
      return this.$route.meta.logoPosition ? {
        'shifted-left': this.logoShifted === 'left',
        'shifted-center': this.logoShifted === 'center',
        'shifted-right': this.logoShifted === 'right',
        'shifted-max-right': this.logoShifted === 'max-right'
      } : this.logoShifted === 'left';
    },
  },

  watch: {
    token () {
      this.getNotifications();
    },

    webSocketNotification: {
      handler () {
        if (!this.webSockets['notifications']) {
          this.getNotifications();
        }
      },

      deep: true
    },

    displayNotifications () {
      if (!this.displayNotifications) {
        this.sendWebSocketMessage('notifications', 'mark_all_as_read', {});
      }
    }
  },

  created () {
    this.initializeApp();
  },

  mounted () {
    if (this.$router.currentRoute.name === "Callback" && process.env.VUE_APP_MODE === MODE.GDDI) {
      localStorage.clear();
    }
    window.addEventListener('focus', this.onFocus);
    this.$store.dispatch('auth/initSession');

    this.initIdleTimer();
    window.addEventListener('storage', this.logoutEventHandler);
    if (this.notifications.length === 0) {
      this.getNotifications();
    }
  },

  methods: {
    async initializeApp () {
        try {
          await this.$store.dispatch('customApp/getCustomSettings');
          await this.$store.dispatch("featureFlag/getFeatureFlagsList");
          if (isLoggedIn()) {
            await this.$store.dispatch('user/getUser');
            this.getInformation();
            if (!this.user.is_cgu_valid) {
              await this.$router.push({ name: 'Cgu' });
            } else if (!this.user.persona) {
              await this.$router.push({ name: 'Persona' });
            }
          }
          const updatedFilters = await initializeFilters();
          this.$store.commit('sites/setFilters', updatedFilters);
        } catch (error) {
            if (error && error.response.data.code !== 'token_not_valid') {
              this.$store.commit('snackbar/apiErrorSnackbar', error);
            }
        } finally {
          this.ready = true;
        }
    },

    getNotifications () {
      if (this.token) {
        this.initializeWebSocket("notifications", this.token,
          { type: 'list' },
          response => {
          const message = JSON.parse(response.data);
            let notifications = message?.content?.results;
            if (Array.isArray(notifications)) {
              this.$store.commit('notifications/setNotifications', notifications);
            }
            if (message?.errors?.length >= 1) {
              for (const error of message.errors) {
                if (error && error.type && error.type !== "disconnected")
                  this.$store.commit('snackbar/apiErrorSnackbar', error);
              }
            }
          });
      }
    },

    dismissNotification (id) {
      this.sendWebSocketMessage('notifications', 'mark_as_dismissed', {message: {"id": id}});
    },

    onFocus () {
      if (!isLoggedIn()) {
        if (!this.$router.currentRoute.meta.authNotRequired) {
          return Router.push({ name: 'LoginGeneral' });
        }
      }
    },

    logout () {
      if (this.$router.currentRoute.name !== 'LoginGeneral') {
        this.closeWebSocketSession('notifications');
        this.$store.dispatch('auth/logout').finally(() => {
          this.$router.push({ name: 'LoginGeneral' });
        });
      } else {
        this.initIdleTimer();
      }
    },

    onExpired () {
      if (this.$router.currentRoute.name !== 'LoginGeneral') {
        this.$router.push({ name: 'LoginGeneral' });
      } else {
        this.initIdleTimer();
      }
    },

    logoutEventHandler (event) {
      if (event.storageArea !== localStorage) return;
      if (event.key === 'logout' && event.oldValue === 'false' && event.newValue === 'true') {
        if (this.$router.currentRoute.name !== 'LoginGeneral') {
          this.$store.commit('auth/resetTokens');
          this.$router.push({ name: 'LoginGeneral' });
        }
      }
    },

    initIdleTimer () {
      new IdleTimer({
        timeout: 30 * 60, // expire after 30min
        onTimeout: () => {
          this.logout();
        },

        onExpired: () => {
          this.onExpired();
        }
      });
    },

    getInformation () {
      const favicon = document.getElementById('favicon');

      if (this.$route.path === "/") {
        favicon.setAttribute('href', require('@/assets/img/favicon/Codoc_favicon.png'));
        document.title = SUITE[this.customSettings.login_page.toUpperCase()].tab_title;
      } else {
        switch (process.env.VUE_APP_MODE) {
          case MODE.CARE:
            favicon.setAttribute('href', require('@/assets/img/favicon/ResearchHubCare_favicon.png'));
            document.title = `codoc Research Hub | care`;
            break;
          case MODE.GDDI:
            favicon.setAttribute('href', require('@/assets/img/favicon/Transparency_favicon.png'));
            document.title = 'codoc Transparency';
            break;
          case MODE.EDS:
            favicon.setAttribute('href', require('@/assets/img/favicon/ResearchHub_favicon.png'));
            document.title = `codoc Research Hub`;
            break;
          case MODE.RESEARCH:
            favicon.setAttribute('href', require('@/assets/img/favicon/DrResearch_favicon.png'));
            document.title = `codoc Research Hub | Workspace`;
            break;
        }
      }
    }
  }
};
</script>